import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/layouts/layout_login.vue'),
    },
    {
        path: '/',
        component: () => import('@/layouts/layout_main.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/views/view_home.vue'),
            },
            {
                path: '/search',
                name: 'search',
                component: () => import('@/views/view_search.vue'),
            },
            {
                path: '/logout',
                name: 'logout',
                component: () => import('@/views/view_logout.vue'),
            },
            {
                path: '/no-twitch-prime',
                name: 'noTwitchPrime',
                component: () => import('@/views/view_add_twitch_prime.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
});

router.beforeEach((to, from, next) => {
    const publicPages = ['login'];
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem('isLogged')
        ? localStorage.getItem('isLogged').toString() === 'true'
        : false;

    if (authRequired && loggedIn === false) {
        next('/login');
    } else if (to.name === 'login' && loggedIn === true) {
        next('/');
    } else {
        next();
    }
});

export default router;
